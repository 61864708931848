import "react-contexify/dist/ReactContexify.css";
import "@/styles/globals.css";
import AuthContextProvider from "@/context/AuthContext";
import ErrorBoundary from "@/components/ErrorBoundary";

export default function App({ Component, pageProps }) {
  return (
    <ErrorBoundary>
      <AuthContextProvider>
        <Component {...pageProps} />
      </AuthContextProvider>
    </ErrorBoundary>
  );
}
