import { firebase_app } from "@/firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

const auth = getAuth(firebase_app);

export const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const router = useRouter();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        if (!router.asPath.includes("/auth")) router.push("/auth/login");
      } else {
        const userData = await fetch(`/api/users/${firebaseUser.uid}`).then(
          (res) => res.json()
        );

        setUser({ ...firebaseUser, ...userData });

        if (router.asPath.includes("/auth")) router.push("/");

        // TODO: uncomment once the role-based route redirection logic is implemented
        // if (router.asPath.includes("/auth") || router.asPath === "/dmt") {
        //   if (userData.role === "supervisor") router.push("/dmt/supervisor");
        //   else if (userData.role === "manager") router.push("/dmt/manager");
        //   else if (userData.role === "agent") router.push("/dmt/agent");
        //   else router.push("/");
        // } else if (
        //   router.asPath.includes("/dmt/supervisor") &&
        //   userData.role !== "supervisor"
        // ) {
        //   // Role-based route redirection logic
        //   router.push("/unauthorized");
        // } else if (
        //   router.asPath.includes("/dmt/manager") &&
        //   userData.role !== "manager"
        // ) {
        //   router.push("/unauthorized");
        // } else if (
        //   router.asPath.includes("/dmt/agent") &&
        //   userData.role !== "agent"
        // ) {
        //   router.push("/unauthorized");
        // }
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
